import * as React from "react";
import parse from 'html-react-parser';
import "./doctors.css";
import Layout from "../layouts/layout";
import { FaFileInvoice } from 'react-icons/fa';
import { Link } from "gatsby";
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";


const ChirurgiensPage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { childs } = props.pageContext;
    const { uri } = props.pageContext;
    return (
        <>
            <Layout>
                <Helmet htmlAttributes={{
                    lang: 'fr-FR',
                }}>
                    <link rel="icon" type="image/x-icon" href={favicon} />
                    <title>{titleMeta}</title>
                    <meta name="description" content={metaDesc} />
                    <meta name="robots" content="index, follow" />
                    <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10925153812"></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10925153812');
      `}
    </script>
                </Helmet>
                <div className="container-fluid service2 pt-5 pb-5">
                    <div className="container intern-page-style">
                        <h1 className="service-title">
                            {title}
                        </h1>
                    </div>
                </div>

                <div className="container-fluid cont3-style pb-5 pt-5">
                    <div className="container ">
                        <div className="row">

                            {childs && childs.map((child, i) => {
                                return (
                                    <div className="col-sm-12 col-md-4 pt-2">
                                        <div className="card h-100" >
                                            <Link to={child.uri} className="link-cv">
                                                <img src={child.featuredImage.sourceUrl} className="card-img-top img-fluid" alt={child.title} />
                                            </Link>
                                            <div className="card-body card-body-intervention   w-100">
                                                <span className="spec-doc">{child.Specialite.specialiste}</span><br />
                                                <Link to={child.uri} className="link-interv">
                                                    <span className="mb-2 card-body-title">{child.title}</span>
                                                    <span className="float-end">
                                                        <Link to={child.uri} className="link-cv">
                                                            <FaFileInvoice />
                                                        </Link>
                                                    </span>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>

                <div className="container-fluid  pb-5 pt-2">
                    <div className="container ">
                        <div className="row">
                            {content && parse(content)}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ChirurgiensPage
